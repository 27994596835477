@import '@matillion/component-library/dist/assets/styles/colours';

.Container {
  border: 1px solid $cloudy;

  &__Header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
