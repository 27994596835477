.RunNewTest {
  &__TestName {
    gap: 1rem;
  }

  &__RightColumn {
    justify-content: right;
  }

  &__LoadingSpinner {
    display: block;
    margin: 1rem auto 0;
  }
}
