.HttpErrorBoundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 3rem auto 0;
  padding: 2rem;

  &__Reload {
    margin-top: 1rem;
  }

  > * {
    margin-bottom: 1rem;
    text-align: center;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}
