@import '@matillion/component-library/dist/assets/styles/colours';

.SquareButton {
  align-self: stretch;
  padding: 1rem;
  border: 1px solid $windy;
  background-color: $white;

  &:hover {
    background-color: $misty;
    cursor: pointer;
  }

  &:active {
    background-color: $cloudy;
  }
}
