.ViewCompletedTests {
  &__IconLabel {
    gap: 1rem;
  }

  &__LoadMoreButton {
    margin: 2rem auto;
  }

  &__LoadingSpinner {
    display: block;
    margin: 1rem auto 0;
  }
}
